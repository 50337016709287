import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

const SecurityTab = (props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [isCurrentPasswordError, setCurrentPasswordError] = useState(false);
  const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState("");

  const [password, setPassword] = useState("");
  const [isPasswordError, setPasswordError] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("");

  const handleBlur = (event) => {
    console.log(event);
    if ((password === "" && confirmPassword === "") || confirmPassword === "") {
      setPasswordError(false);
      setPasswordErrorMsg("");
      setConfirmPasswordError(false);
      setConfirmPasswordErrorMsg("");
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMsg("Passwords do not match");
    } else if (password === currentPassword) {
      setPasswordError(true);
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMsg("Password matches current password");
    } else {
      setPasswordError(false);
      setPasswordErrorMsg("");
      setConfirmPasswordError(false);
      setConfirmPasswordErrorMsg("");
    }
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>Update your security details.</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Change Password</Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Current Password"
              name="currentPassword"
              type="password"
              autoComplete="password"
              value={currentPassword}
              error={isCurrentPasswordError}
              helperText={currentPasswordErrorMsg}
              onChange={({ target: { value } }) => setCurrentPassword(value)}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Password"
              name="password"
              type="password"
              value={password}
              error={isPasswordError}
              helperText={passwordErrorMsg}
              onChange={({ target: { value } }) => setPassword(value)}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              error={isConfirmPasswordError}
              helperText={confirmPasswordErrorMsg}
              onChange={({ target: { value } }) => setConfirmPassword(value)}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button>Update</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SecurityTab;
