export const data = {
  pages: [
    {
      name: "Services",
      title: "Services",
      introduction:
        "At Fourth Realm Innovations, we provide a wide array of technical services tailored to meet the needs of individuals, small businesses, and educational institutions. Our offerings harness the power of the latest technologies, backed by industry-standard certifications and years of hands-on experience.",
      list: [
        {
          name: "Technical Support & Repair",
          iconName: "Computer",
          bullets: [
            "Comprehensive computer and device repair services",
            "Regular maintenance, tune-ups, and hardware upgrades",
            "Remote troubleshooting and support",
          ],
        },
        {
          name: "Network Solutions",
          iconName: "Wifi",
          bullets: [
            "Home and small office network setup and maintenance",
            "Wireless network optimization for coverage and speed",
            "Secure VPN configuration for safe and private internet access",
          ],
        },
        {
          name: "Cybersecurity Services",
          iconName: "Shield",
          bullets: [
            "Personalized cybersecurity assessments to identify potential vulnerabilities",
            "Implementation of security measures, including firewalls and antivirus solutions",
            "Penetration testing to evaluate your defenses and provide remediation strategies",
          ],
        },
        {
          name: "Cloud Computing Services",
          iconName: "Cloud",
          bullets: [
            "AWS cloud-based application development and deployment",
            "Cloud environment optimization for performance and cost",
            "Consulting on cloud adoption and administration for scalable growth",
          ],
        },
        {
          name: "Web Development",
          iconName: "Web",
          bullets: [
            "Full-stack web development, from initial design to launch",
            "Revamping existing websites for improved user experience and SEO",
            "E-commerce platform development for online businesses",
          ],
        },
        {
          name: "IT Project Management",
          iconName: "Timeline",
          bullets: [
            "Coordinating technology projects to ensure timely and cost-effective delivery",
            "Strategic planning and execution from inception to completion",
            "Agile and responsive project adjustments to meet your evolving needs",
          ],
        },
        {
          name: "Training & Education",
          iconName: "School",
          bullets: [
            "Customized IT training sessions for individuals or teams",
            "Workshops on cybersecurity awareness and best practices",
            "Coding and web development bootcamps",
          ],
        },
        {
          name: "Consultancy Services",
          iconName: "Person",
          bullets: [
            "One-on-one IT consultations to align technology with business objectives",
            "Long-term tech strategy development for growth and innovation",
            "On-site and remote consultation options available",
          ],
        },
      ],
      conclusion:
        "With a foundation built on an Associate in Science in Computer Security and Networking Technologies, and real-world experience ranging from software engineering to advanced technical repair, Fourth Realm Innovations is your partner in navigating the complexities of modern technology.",
      farewell:
        "Contact us today to discuss how we can empower your tech journey.",
    },
    {
      name: "ScheduleService",
      title: "Schedule a Service",
      introduction:
        "Select a service category below, then specify your needs by choosing from the provided options.",
      services: [
        {
          category: "Technical Support & Repair",
          options: [
            {
              subcategory: "Software Installation",
              items: [
                "Operating System Installation",
                "Application Setup",
                "Security Software Implementation",
              ],
            },
            {
              subcategory: "Hardware Repair",
              items: [
                "Component Replacement",
                "Memory Upgrade",
                "Cooling System Maintenance",
              ],
            },
            {
              subcategory: "Performance Tuning",
              items: [
                "System Diagnostics",
                "Speed Optimization",
                "Cleaning and Maintenance",
              ],
            },
          ],
        },
        {
          category: "Network Solutions",
          options: [
            {
              subcategory: "Network Setup",
              items: [
                "Router Configuration",
                "Network Security Implementation",
                "IoT Device Integration",
              ],
            },
            {
              subcategory: "Network Maintenance",
              items: [
                "Performance Assessment",
                "Firmware Updates",
                "Security Patch Application",
              ],
            },
            {
              subcategory: "VPN Configuration",
              items: [
                "VPN Setup for Secure Access",
                "Multi-Device Connection",
                "VPN Troubleshooting",
              ],
            },
          ],
        },
        {
          category: "Cybersecurity Services",
          options: [
            {
              subcategory: "Security Assessment",
              items: [
                "Vulnerability Scanning",
                "Risk Analysis",
                "Security Policy Review",
              ],
            },
            {
              subcategory: "Security Implementation",
              items: [
                "Firewall Setup",
                "Antivirus Deployment",
                "Multi-Factor Authentication Setup",
              ],
            },
            {
              subcategory: "Penetration Testing",
              items: [
                "Controlled Attack Simulation",
                "Security Breach Prevention Planning",
                "Post-Test Analysis and Fortification",
              ],
            },
          ],
        },
        {
          category: "Cloud Computing Services",
          options: [
            {
              subcategory: "Development & Deployment",
              items: [
                "Cloud-Based Application Development",
                "Deployment Automation",
                "CI/CD Pipelines",
              ],
            },
            {
              subcategory: "Cloud Optimization",
              items: [
                "Performance Tuning",
                "Cost-Reduction Analysis",
                "Scaling Strategy",
              ],
            },
            {
              subcategory: "Cloud Administration",
              items: [
                "User Account Management",
                "Resource Monitoring",
                "Backup and Recovery Procedures",
              ],
            },
          ],
        },
        {
          category: "Web Development",
          options: [
            {
              subcategory: "Website Creation",
              items: ["Website Design", "CMS Setup", "Mobile Optimization"],
            },
            {
              subcategory: "Website Revamp",
              items: [
                "User Experience Improvement",
                "Conversion Optimization",
                "SEO Enhancement",
              ],
            },
            {
              subcategory: "E-commerce Development",
              items: [
                "Online Store Setup",
                "Payment Gateway Integration",
                "Shopping Cart Configuration",
              ],
            },
          ],
        },
        {
          category: "IT Project Management",
          options: [
            {
              subcategory: "Project Initiation",
              items: [
                "Requirement Gathering",
                "Scope Definition",
                "Resource Allocation",
              ],
            },
            {
              subcategory: "Project Planning",
              items: [
                "Milestone Setting",
                "Workflow Design",
                "Budget Forecasting",
              ],
            },
            {
              subcategory: "Project Execution",
              items: [
                "Task Delegation",
                "Progress Tracking",
                "Quality Assurance",
              ],
            },
          ],
        },
        {
          category: "Training & Education",
          options: [
            {
              subcategory: "IT Skills Training",
              items: [
                "Basic Computing Skills",
                "Office Software Proficiency",
                "Internet and Email Best Practices",
              ],
            },
            {
              subcategory: "Cybersecurity Workshops",
              items: [
                "Phishing Awareness",
                "Password Management",
                "Secure Online Behavior",
              ],
            },
            {
              subcategory: "Development Bootcamps",
              items: [
                "Front-End Development",
                "Back-End Development",
                "Full-Stack Development Projects",
              ],
            },
          ],
        },
        {
          category: "Consultancy Services",
          options: [
            {
              subcategory: "Technology Assessment",
              items: [
                "Current Technology Evaluation",
                "Needs Analysis",
                "Strategic Recommendation",
              ],
            },
            {
              subcategory: "Tech Strategy Development",
              items: [
                "Technology Roadmapping",
                "Innovation Planning",
                "Long-Term IT Strategy Formulation",
              ],
            },
            {
              subcategory: "Specialized IT Consultation",
              items: [
                "Cloud Migration Strategy",
                "Cybersecurity Framework Development",
                "Custom Software Solution Advising",
              ],
            },
          ],
        },
      ],
      conclusion: {
        title: "Next Steps",
        steps: [
          {
            order: 1,
            text: "Choose your primary service category.",
          },
          {
            order: 2,
            text: "Select up to 5 specific services for your appointment.",
          },
          {
            order: 3,
            text: "Provide any additional details about your service needs.",
          },
          {
            order: 4,
            text: "Select a date and time that works best for you.",
          },
          {
            order: 5,
            text: "Submit your request, and we will confirm your appointment shortly.",
          },
        ],
        farewell:
          "We look forward to serving you with excellence and expertise.",
      },
    },
    {
      name: "Home Page",
      title: "Home",
      heading:
        "Welcome to Fourth Realm Innovations - Your Partner in Tech Solutions",
      sections: [
        {
          title: "Empowering You to Maximize Productivity Through Technology",
          content:
            "At Fourth Realm Innovations, we believe in the power of technology to transform lives and businesses. Our mission is to empower you, whether you're a tech newcomer, a bustling small business, or simply someone looking to streamline their digital world.",
        },
        {
          title: "Innovative, Tech-Savvy, and Transparent",

          content:
            "We're not just another tech service provider. We're innovators and problem-solvers dedicated to offering you the best solutions with complete transparency. No unnecessary fixes, no hidden costs - just honest, straightforward tech support that puts your needs first.",
        },
        {
          title: "Comprehensive Services Tailored for You",

          content:
            "Our wide range of services means you get comprehensive solutions tailored to your specific needs. From technical support to network solutions, cybersecurity to cloud services, and web development - we cover all your tech bases. And with a personal touch! As the sole operator and service provider, I, Jake, take immense pride in the quality of my work and the relationships I build with my clients.",
          button: {
            text: "Explore Our Services",
            to: "/services",
            variant: "contained",
            disabled: false,
          },
        },

        {
          title: "For Everyone Who Values Time and Efficiency",

          content:
            "Our services are designed for those who value their time. Just like the client in commercial real estate who understood the worth of professional tech support, we help you focus on what you do best, while we handle the tech side. Whether you're a non-tech-savvy individual, a small-to-medium-sized business looking to upgrade, or someone who'd rather not delve into the intricacies of technology, we're here for you.",
        },

        {
          title: "Professionalism in Every Interaction",

          content:
            "Expect a professional approach in every interaction. Business casual attire, punctuality, and a keen eye for detail reflect our commitment to excellence in technical services. We're redefining the industry standard, proving that tech support is not just a fix-it job but a sophisticated, professional service.",
        },

        {
          title: "Ready to Enhance Your Tech Experience?",

          content:
            "Don't let technical glitches slow you down. Click on the \"Request a Service\" button and let's get your technology working for you, seamlessly and efficiently.",
          button: {
            text: "Request a Service",
            to: "/request-service",
            variant: "outlined",
            disabled: false,
          },
        },

        {
          title: "Technology for Everyone",

          content:
            "Our commitment extends beyond just fixing problems. We strive to make technology accessible and beneficial for all, unlocking its potential to improve your life and work. Stay tuned for our community initiatives and learn how we're making technology accessible to everyone.",
        },

        {
          title: "Contact Us",

          content:
            "Have a question or need some advice? Feel free to reach out. We're here to help you navigate the tech world with ease and confidence.",
          button: {
            text: "Contact Us",
            to: "/contact",
            variant: "outlined",
            disabled: false,
          },
        },
      ],
    },
    {
      title: "About Us",
      name: "About Us",
      content: "",
    },
    {
      title: "Pricing",
      name: "Pricing",
      content: "",
    },
    {
      title: "Testimonials and Reviews",
      name: "Testimonials and Reviews",
      content: "",
    },
    {
      title: "Portfolio/Case Studies",
      name: "Portfolio/Case Studies",
      content: "",
    },
    {
      title: "Blog/Insights",
      name: "Blog",
      content: "",
      blogs: {},
    },
    {
      title: "FAQs",
      name: "FAQs",
      content: "",
    },
    {
      title: "Contact Us",
      name: "Contact Us",
      content: "",
    },
    {
      title: "Resources/Support",
      name: "Resources/Support",
      content: "",
    },
    {
      title: "Careers",
      name: "Careers",
      content: "",
    },
    {
      title: "Privacy Policy & Terms of Service",
      name: "Privacy Policy & Terms of Service",
      content: {
        privacyPolicy: {
          title: "Privacy Policy",
          sections: [
            {
              title: "Introduction",
              content:
                'Welcome to Fourth Realm Innovations ("we", "our", or "us"). Our Privacy Policy explains how we collect, use, disclose, and protect your information. This policy applies to all users of our services and visitors to our website.',
            },
            {
              title: "Information Collection",
              content:
                "We collect information you provide directly to us when you use our services, such as when you request support, contact us with questions, or schedule a service. This information may include your name, email address, phone number, and payment information.",
            },
            {
              title: "Use of Information",
              content:
                "The information we collect is used to fulfill your requests, provide our services, communicate with you, and improve our offerings.",
            },
            {
              title: "Information Sharing and Disclosure",
              content:
                "We do not share your personal information with third parties, except as necessary to provide our services, comply with the law, or protect our rights.",
            },
            {
              title: "Data Security",
              content:
                "We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.",
            },
            {
              title: "User Rights",
              content:
                "You have the right to access, update, or delete your personal information at any time by contacting us.",
            },
            {
              title: "Cookies and Tracking Technologies",
              content:
                "We may use cookies and similar technologies to enhance your experience on our site, analyze our traffic, and provide personalized content and advertising.",
            },
            {
              title: "Data Retention",
              content:
                "We retain your information for as long as necessary to provide you with our services and as required by law.",
            },
            {
              title: "Children's Privacy",
              content:
                "Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children.",
            },
            {
              title: "Changes to the Privacy Policy",
              content:
                "We may update this policy from time to time. We will notify you of any changes by posting the new policy on our website.",
            },
            {
              title: "Contact Information",
              content:
                "If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].",
            },
          ],
        },
        termsOfService: {
          title: "Terms of Service",
          sections: [
            {
              title: "Introduction",
              content:
                "By accessing or using the services provided by Fourth Realm Innovations, you agree to be bound by these Terms of Service.",
            },

            {
              title: "Description of Service",
              content:
                "We provide technical support, repair, network solutions, cybersecurity services, cloud computing services, web development, IT project management, training and education, and consultancy services.",
            },

            {
              title: "User Accounts and Responsibilities",
              content:
                "When creating an account, you agree to provide accurate information and keep your account secure. You are responsible for all activities that occur under your account.",
            },

            {
              title: "Service Use Restrictions",
              content:
                "You may not use our services for any illegal or unauthorized purpose. You must not, in the use of the service, violate any laws.",
            },

            {
              title: "Intellectual Property Rights",
              content:
                "All content provided on our services is the property of Fourth Realm Innovations or its licensors and is protected by intellectual property laws.",
            },

            {
              title: "User-Generated Content",
              content:
                "You retain the rights to any content you submit, post, or display on or through our services.",
            },

            {
              title: "Termination of Use",
              content:
                "We may terminate or suspend your access to our services at any time, without prior notice or liability, for any reason.",
            },

            {
              title: "Disclaimer of Warranties",
              content:
                'Our services are provided "as is" without any warranties of any kind.',
            },

            {
              title: "Limitation of Liability",
              content:
                "Fourth Realm Innovations shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from the use of our services.",
            },

            {
              title: "Indemnification",
              content:
                "You agree to indemnify and hold harmless Fourth Realm Innovations from any demands, loss, liability, claims or expenses made against us by any third party due to or arising out of your use of our services.",
            },

            {
              title: "Governing Law",
              content:
                "These Terms shall be governed by the laws of [Your State/Country] without regard to its conflict of law provisions.",
            },

            {
              title: "Dispute Resolution",
              content:
                "Any disputes will be resolved through final and binding arbitration, except as otherwise agreed by the parties.",
            },

            {
              title: "Changes to Terms",
              content:
                "We reserve the right to modify these terms at any time. By continuing to use the services after changes are made, you agree to be bound by the revised terms.",
            },
            {
              title: "Contact Information",
              content:
                "For any questions about these Terms of Service, please contact us at [Your Contact Information].",
            },
          ],
        },
      },
    },
    {
      title: "Newsletter Sign-Up",
      name: "Newsletter Sign-Up",
      content: "",
    },
    {
      title: "Social Media Links",
      name: "Social Media Links",
      content: "",
    },
  ],
};
