import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const PreferencesTab = (props) => {
  const [timeZone, setTimeZone] = useState("");

  const handleChange = ({ target: { value } }) => {
    setTimeZone(value);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Change Timezone</Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select value={timeZone} onChange={handleChange} label="Timezone">
                {Intl.supportedValuesOf("timeZone").map((tz) => (
                  <MenuItem value={tz} key={tz}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button>Update</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreferencesTab;
