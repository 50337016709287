import { Button, CircularProgress } from "@mui/material";

const ButtonWithLoading = ({
  children,
  isLoading,
  sx,
  type = "submit",
  variant = "outlined",
  color = "primary",
  ...otherProps
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      disabled={isLoading}
      sx={{ width: "200px", ...sx }}
      {...otherProps}
    >
      {isLoading ? <CircularProgress size={"24.5px"} /> : children}
    </Button>
  );
};

export default ButtonWithLoading;
