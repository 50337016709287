import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

const PersonalInformationTab = (props) => {
  const { value } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>Change your personal information.</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={({ target: { value } }) => setFirstName(value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={({ target: { value } }) => setLastName(value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone Number"
            name="phone"
            value={phone}
            onChange={({ target: { value } }) => setPhone(value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button>Update</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalInformationTab;
