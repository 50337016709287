import { Container, Typography } from "@mui/material";

const HelpPage = (props) => {
  return (
    <Container maxWidth={"md"} sx={{ mt: 2 }}>
      <Typography variant="h3">Help</Typography>
    </Container>
  );
};

export default HelpPage;
