import { Box, Container, Typography } from "@mui/material";
import { marked } from "marked";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ShareBar from "./ShareBar";
import LoadingIcon from "../LoadingIcon";
import SubmitCommentCard from "./SubmitCommentCard";
import CommentCard from "./CommentCard";
import ApplicationContext from "../../Context";
import { BlogContext } from "./BlogContext";
import { withBlogContext } from "./withBlogContext";
import dayjs from "dayjs";

const useBlogContent = (slug) => {
  const [blogContent, setBlogContent] = useState();
  const bucket = "fourthrealminnovations";
  const key = slug;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://s47hgo1zcb.execute-api.us-east-1.amazonaws.com/blogContent?bucket=${bucket}&key=${key}.md`
      );
      const data = await response.json();
      setBlogContent(data.trim());
    };
    fetchData();
  }, []);
  return blogContent;
};
const useBlogComments = (slug) => {
  const { comments, setComments } = useContext(BlogContext);
  const url = `https://s47hgo1zcb.execute-api.us-east-1.amazonaws.com/blog-comments?slug=${slug}`;

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url, {
        method: "GET",
      });
      console.log("response", response);
      const data = await response.json();
      console.log(data);
      setComments(
        data.message.sort(
          (a, b) => dayjs(a.timestamp).unix() - dayjs(b.timestamp).unix()
        )
      );
    };
    fetchData();
  }, []);
  return comments;
};

const BlogPost = () => {
  let { slug } = useParams();
  const blogContent = useBlogContent(slug);
  const comments = useBlogComments(slug);
  const { token } = useContext(ApplicationContext);

  return (
    <Container maxWidth="md">
      <ShareBar slug={slug} />
      {blogContent && comments ? (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: marked.parse(blogContent),
            }}
          />
          <Box component="hr" />
          <Typography variant="h4" sx={{ mb: 2 }}>
            Comments
          </Typography>
          {comments !== null &&
            comments.map(({ user, text, timestamp, commentId }) => (
              <CommentCard
                key={commentId}
                user={user}
                text={text}
                timestamp={timestamp}
              />
            ))}
          {token && <SubmitCommentCard slug={slug} />}
        </>
      ) : (
        <LoadingIcon />
      )}
    </Container>
  );
};

export default withBlogContext(BlogPost);
