import { Container, Typography } from "@mui/material";
import { useContext } from "react";
import ApplicationContext from "../../Context";
import VerticalTabs from "./VerticalTabs";

const AccountPage = (props) => {
  const { loggedInEmail } = useContext(ApplicationContext);

  return (
    <Container maxWidth="md">
      <Typography variant="h1">Account</Typography>
      <Typography sx={{ mb: 2 }}>Logged-In User: {loggedInEmail}</Typography>
      <VerticalTabs />
    </Container>
  );
};

export default AccountPage;
