import React, { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./TabPanel";
import PersonalInformationTab from "./tabs/PersonalInformationTab";
import CloseAccountTab from "./tabs/CloseAccountTab";
import SecurityTab from "./tabs/SecurityTab";
import PreferencesTab from "./tabs/PreferencesTab";
import NotificationsTab from "./tabs/NotificationsTab";
import BillingAndPaymentsTab from "./tabs/BillingAndPaymentsTab";
import ServiceHistoryTab from "./tabs/ServiceHistoryTab";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const pages = [
  { title: "Personal Information", component: PersonalInformationTab },
  { title: "Security", component: SecurityTab },
  { title: "Preferences", component: PreferencesTab },
  { title: "Notifications", component: NotificationsTab },
  { title: "Billing and Payments", component: BillingAndPaymentsTab },
  { title: "Service History", component: ServiceHistoryTab },
  { title: "Close Account", component: CloseAccountTab },
];

export default function VerticalTabs() {
  const [value, setValue] = useState(0);

  const [tabOrientation, setTabOrientation] = useState("horizontal");

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setTabOrientation("horizontal");
    } else {
      setTabOrientation("vertical");
    }
  };

  useEffect(() => {
    handleResize(); // Set initial state based on current window width
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: { lg: "flex" },
      }}
    >
      <Tabs
        orientation={tabOrientation}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={value}
        onChange={handleChange}
        aria-label="Accounts settings tabs"
        sx={{ borderRight: 1, borderColor: "divider", flex: "0 0 200px" }}
      >
        {pages.map(
          ({ title, component: Component }, index) =>
            Component && (
              <Tab label={title} {...a11yProps(index)} key={`${title}-tab`} />
            )
        )}
      </Tabs>
      {pages.map(({ title, component: Component }, index) => (
        <TabPanel
          value={value}
          index={index}
          title={title}
          key={`${title}-tabpanel`}
        >
          {Component && <Component />}
        </TabPanel>
      ))}
    </Box>
  );
}
