import { Card, CardContent, Typography } from "@mui/material";
import dayjs from "dayjs";

const CommentCard = (props) => {
  const { user, text, timestamp } = props;
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {user} - {dayjs(timestamp).format("MM/D/YYYY h:mm A")}
        </Typography>
        <Typography variant="body2" component={"p"}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default CommentCard;
