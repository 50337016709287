import { TextField } from "@mui/material";
import { useContext } from "react";
import LoginContext from "./LoginContext";

const FormTextField = (props) => {
  const { name, label } = props;
  const { formData, errors, setFormData } = useContext(LoginContext);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <TextField
      label={label}
      name={name}
      onChange={handleChange}
      value={formData[name] || ""}
      error={errors[name] != null}
      helperText={errors[name]}
      {...props}
    />
  );
};

export default FormTextField;
