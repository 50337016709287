import { useContext } from "react";
import ApplicationContext from "./Context";
import { Navigate, useLocation } from "react-router";

const ProtectedRoute = ({ children }) => {
  const { token } = useContext(ApplicationContext);
  const location = useLocation();

  console.log("token", token);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
