import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import * as yup from "yup";
import ApplicationContext from "../../Context";
import ButtonWithLoading from "../ButtonWithLoading";
import { BlogContext } from "./BlogContext";
import dayjs from "dayjs";

const validationSchema = yup.object().shape({
  commentText: yup.string().required("You must enter something to comment"),
});

const SubmitCommentCard = (props) => {
  const { slug } = props;
  const { loggedInEmail, token } = useContext(ApplicationContext);
  const { comments, setComments } = useContext(BlogContext);
  const [commentText, setCommentText] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    setSubmitting(true);
    setValidationErrors([]);
    event.preventDefault();

    try {
      await validationSchema.validate({ commentText }, { abortEarly: false });
      submit();
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => (errors[err.path] = err.message));
      setValidationErrors(errors);
      setSubmitting(false);
    }
  };

  const submit = async () => {
    const url = `https://s47hgo1zcb.execute-api.us-east-1.amazonaws.com/blog-comments`;
    const comment = {
      user: loggedInEmail.split("@")[0],
      text: commentText,
      blogSlug: slug,
      timestamp: new Date().toISOString(),
    };
    const params = {
      method: "POST",
      body: JSON.stringify({
        token,
        comment,
      }),
    };
    try {
      const response = await fetch(url, params);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCommentText("");
        setComments(
          [...comments, comment].sort(
            (a, b) => dayjs(a.timestamp).unix() - dayjs(b.timestamp).unix()
          )
        );
        setSubmitting(false);
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const handleChange = ({ target: { value } }) => {
    setCommentText(value);
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Leave a Comment
        </Typography>
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            label="Your Comment"
            onChange={handleChange}
            value={commentText}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            margin="normal"
            error={Boolean(validationErrors?.["commentText"])}
            helperText={validationErrors?.["commentText"] || ""}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <ButtonWithLoading isLoading={submitting}>
              Submit Comment
            </ButtonWithLoading>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubmitCommentCard;
