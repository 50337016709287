import { useState } from "react";
import { BlogContext } from "./BlogContext";

export const BlogContextProvider = ({ children }) => {
  const [comments, setComments] = useState([]);
  const context = { comments, setComments };
  return (
    <BlogContext.Provider value={context}>{children}</BlogContext.Provider>
  );
};
